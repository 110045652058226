export function initStoragePage() {
    window.addEventListener('message', (e) => {
        const data = e.data;
        console.log(e);
        if (!data) {
            return;
        }
        console.log( window.location.origin);

        if (e.origin === window.location.origin) {
            return;
        }

        console.log(process.env.VUE_APP_URL_ORIGIN, e.origin);
        if (!process.env.VUE_APP_URL_ORIGIN.split(';').includes(e.origin)) {
            return;
        }

        if (data.origin) {
            sendInfo(data.origin);
            return;
        }

        initStorage(data, 'layout');
    }, false);
}

function sendInfo(origin) {
    parent.postMessage({
        auth: localStorage.auth || null,
        layout: localStorage.layout || null
    }, origin);
}

function initStorage(data, key) {
    if (data[key]) {
        localStorage.setItem(key, data[key]);
    } else {
        localStorage.removeItem(key);
    }
}