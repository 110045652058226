import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import axios from 'axios';
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";
import { refresh } from "@/helpers/auth";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';

import mitt from 'mitt';
export const emitter = mitt();

import { initStoragePage } from "@/helpers/domains";

// cross domain iframe
if (window.location.pathname === '/_storage') {
    initStoragePage();
} else {
    // Update User info scheduler
    if (store.getters['auth/loggedIn']) {
        refresh();
    }

    setInterval(() => {
        if (store.getters['auth/loggedIn']) {
            refresh();
        }
    }, 10000);
}

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

axios.defaults.baseURL = process.env.VUE_APP_URL_REST_API ?? '';

axios.interceptors.request.use(function (config) {
    const token = store.getters['auth/token'];

    if (token) {
        config.headers.Authorization = token;
    }

    return config;
});

store.subscribe( (mutation, state) => {
    localStorage.setItem('layout', JSON.stringify(state.layout));
    localStorage.setItem('auth', JSON.stringify(state.auth));
})
const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(Toast)
    .use(i18n)
    .use(vClickOutside);

app.config.globalProperties.emitter = emitter;
app.mount('#app');