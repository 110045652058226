import axios from 'axios';
import { useToast } from "vue-toastification";
import router from "../router";
import store from "@/state/store";
import { printError } from '@/helpers/request';

const toast = useToast();

export function login(user, callbacks) {
    axios.post('/login', {
        login: user.login,
        password: user.password,
        remember: user.remember
    }).then(({ data }) => {
        if (data.authorisation && data.user) {
            toast.success('Вы успешно авторизовались!');
            const token = data.authorisation.type + ' ' + data.authorisation.token;
            store.dispatch('auth/logIn', { token, user: data.user });
            router.push('/');
        } else {
            toast.warning('Неверный запрос o_0');
            console.log(data);
        }
    }).catch(printError).finally(() => {
        if (callbacks?.end) callbacks.end();
    });
}

export function register(user, callbacks) {
    axios.post('/register', {
        login: user.login,
        password: user.password,
        email: user.email
    }).then(({ data }) => {
        if (data.authorisation && data.user) {
            toast.success('Вы успешно зарегистрировались!');
            const token = data.authorisation.type + ' ' + data.authorisation.token;
            store.dispatch('auth/logIn', { token, user: data.user });
            router.push('/');
        } else {
            toast.warning('Неверный запрос o_0');
            console.log(data);
        }
    }).catch(printError).finally(() => {
        if (callbacks?.end) callbacks.end();
    });
}

export function refresh() {
    axios.post('/refresh').then(({ data }) => {
        if (data.authorisation && data.user) {
            const token = data.authorisation.type + ' ' + data.authorisation.token;
            store.dispatch('auth/refresh', { token, user: data.user });
        }
    }).catch((error) => {
        if (error.response?.status !== 401) {
            return;
        }
        logout();
        store.dispatch('auth/logOut');
        router.push('/logout');
        toast.warning('Выс слишком долго не было и мы вышли из вашего аккаунта, чтобы сюда не зашел кто-то ещё =)');
    });
}

export function logout() {
    axios.post('/logout').catch(printError);
}

export async function forgot(email, callbacks) {
   return axios.get('/forgot', {
       params: {
           email
       }
   }).then(({ data }) => {
       if (callbacks?.success) callbacks.success();
       toast.success('Письмо отправлено на почтовый ящик');
       return data;
   }).catch(printError);
}

export async function reset({ password, token, login }) {
    return axios.post('/reset', {
        password,
        token,
        login
    }).then(({ data }) => {
        toast.success('Пароль успешно сброшен');
        router.push('/login');
        return data;
    }).catch(printError);
}