export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login"),
    meta:  {
      title: "Авторизация",
      authRequired: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/auth/register"),
    meta: {
      title: "Регистрация",
      authRequired: false,
    },
  },
   {
    path: "/forgot",
    name: "forgot-password",
    component: () => import("../views/auth/forgot"),
    meta: {
      title: "Восстановление пароля",
    },
  },
  {
    path: "/reset/:login/:token",
    name: "reset-password",
    component: () => import("../views/auth/reset"),
    meta: {
      title: "Восстановление пароля",
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Главная страница",
      authRequired: true,
    },
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/home/settings",
    name: "home-edit",
    meta: {
      title: "Настройки аккаунта",
      authRequired: true,
    },
    component: () => import("../views/home/settings.vue"),
  },
  {
    path: "/home/skin",
    name: "home-skin",
    meta: {
      title: "Скин",
      authRequired: true,
    },
    component: () => import("../views/home/skin.vue"),
  },
  {
    path: "/home/cape",
    name: "home-cape",
    meta: {
      title: "Плащ",
      authRequired: true,
    },
    component: () => import("../views/home/cape.vue"),
  },
  {
    path: "/home/balance",
    name: "home-balance",
    meta: {
      title: "Баланс",
      authRequired: true,
    },
    component: () => import("../views/home/balance.vue"),
  },
  {
    path: "/donate",
    name: "home-donate",
    meta: {
      title: "Донат",
      authRequired: true,
    },
    component: () => import("../views/donate/index.vue"),
  },
  {
    path: "/users/:name",
    name: "users-profile",
    meta: {
      title: "Профиль пользователя",
      authRequired: true,
    },
    component: () => import("../views/users/index.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: {
      title: "Пользователи",
      authRequired: true,
    },
    component: () => import("../views/users/search.vue"),
  },
  {
    path: "/payment/success",
    name: "payment-success",
    meta: {
      title: "Успешная оплата",
    },
    component: () => import("../views/payment/success.vue"),
  },
  {
    path: "/payment/error",
    name: "payment-error",
    meta: {
      title: "Ошибка оплаты",
    },
    component: () => import("../views/payment/error.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Выход",
      logout: true,
    },
    component: () => import("../views/auth/logout")
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import("../views/errors/not-found.vue"),
  },
];