import { createWebHistory, createRouter } from "vue-router";
import store from "@/state/store";
import { logout } from "@/helpers/auth";

// import store from '@/state/store'
import routes from './routes'
import appConfig from "../../app.config";

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
})

router.beforeEach(async (routeTo, routeFrom, next) => {
    const auth = store.getters["auth/loggedIn"];
    document.body.classList.remove('vertical-sidebar-enable');

    if (routeTo.meta.authRequired === true) {
      if (!auth) {
        return next('/login');
      }

    } else if (routeTo.meta.authRequired === false) {
      if (auth === true) {
        return next({ name: 'default' });
      }
    }

    if (routeTo.meta.logout === true) {
      try {
        await logout();
      } catch (ignored) {
        console.log(ignored);
      }
      store.dispatch('auth/logOut');
      return next();
    }

    next();
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }
  document.title = routeTo.meta.title || '0_o' + ' | ' + appConfig.title;
  // If we reach this point, continue resolving the route.
  next()
})

export default router
