import axios from 'axios';

export const state = JSON.parse(localStorage.getItem('auth')) || {
    token: null,
    user: {}
}

export const mutations = {
    SET_CURRENT_USER(state, { token, user } = {}) {
        state.token = token;
        state.user = user;

        if (!token) {
            localStorage.removeItem('auth');
        }
    },
}

export const getters = {
    loggedIn(state) {
        return !!state.token
    },
    token(state) {
        return state.token
    },
    user(state) {
        return state.user;
    },
}

export const actions = {
    logIn({ commit }, { token, user } = {}) {
        commit('SET_CURRENT_USER', { token, user });
    },

    refresh({ commit }, { token, user } = {}) {
        commit('SET_CURRENT_USER', { token, user });
    },

    logOut({ commit } = {}) {
        commit('SET_CURRENT_USER')
        delete axios.defaults.headers.common['Authorization'];
    },
}
