import { useToast } from "vue-toastification";
const toast = useToast();

export function printError(error) {
    if (error.response?.data) {
        if (error.response.data.error) {
            toast.error(error.response.data.error);
        } else if (error.response.data.errors) {
            const key = Object.keys(error.response.data.errors)[0];
            toast.error(error.response.data.errors[key][0]);
        }
    }
}